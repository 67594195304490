import React, { Component } from 'react'
import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

export default class GenericNotFoundView extends Component {
  render() {
    return (
      <Result
        style={{ width: '600px', margin: '40px auto' }}
        title="404"
        subTitle={<FormattedMessage key="subTitle" defaultMessage="Sorry, the page you visited does not exist." />}
        extra={
          <Link to="/home">
            <Button type="primary">
              <FormattedMessage key="homeButton" defaultMessage="Back home" />
            </Button>
          </Link>
        }
      />
    )
  }
}
