import React from 'react'
import { Route } from 'react-router-dom'
import EmptyLayout from 'layouts/empty/'

const PublicRoute = ({ ...props }) => {
  const Layout = props.layout || EmptyLayout

  return (
    <Layout>
      <Route {...props} />
    </Layout>
  )
}

export default PublicRoute
