import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ModalRoute } from 'react-router-modal'

// Routes
import PublicRoute from './public-route'
import UserRoute from './user-route'

// Layouts
import EmptyLayout from 'layouts/empty'
import UserLayout from 'layouts/user'
import AuthLayout from 'layouts/auth'

// Public
import GenericNotFoundView from 'views/not-found'
import LoginView from 'views/login'
import SignupView from 'views/signup'
import ConfirmView from 'views/confirm'
import ForgotPasswordView from 'views/forgot-password'
import ResetPasswordView from 'views/reset-password'
import ChangeEmailView from 'views/change-email'

import GetTokenView from 'views/get-token'

// User
import UserHomeView from 'views/user/home'
import UserHomeModalExampleModal from 'views/user/modal-example'
import UserSupportView from 'views/user/support'
import UserSalePresentations from 'views/user/sale-presentations'
import UserWelcomeGuide from 'views/user/welcomeguide'
import UserSaleVideos from 'views/user/sale-videos'
import UserActivitiesView from 'views/user/activities'

import UserSettingsView from 'views/user/settings/index'
import UserSettingsChangePasswordView from 'views/user/settings/change-password'
import UserSettingsRemoveAccountView from 'views/user/settings/remove-account'
import UserSettingsChangeEmailView from 'views/user/settings/change-email'
import UserSettingsTestInfoView from 'views/user/settings/test-info'
import UserSettingsImageView from 'views/user/settings/image'
import BigLoader from 'components/BigLoader'

const AdminRoutes = lazy(() => import('./AdminRoutes'))

const Routes = () => {
  return (
    <>
      <Suspense fallback={<BigLoader />}>
        <Switch>
          <PublicRoute name="GetToken" path="/get-token" component={GetTokenView} layout={AuthLayout} />
          <PublicRoute name="Log in" path="/login" component={LoginView} layout={AuthLayout} />
          <PublicRoute name="Sign up" path="/signup" component={SignupView} layout={AuthLayout} />
          <PublicRoute name="Confirm" path="/confirm/:token" component={ConfirmView} layout={AuthLayout} />
          <PublicRoute name="Get a new password" path="/forgot-password" component={ForgotPasswordView} layout={AuthLayout} />
          <PublicRoute name="Reset your password" path="/reset-password/:token" component={ResetPasswordView} layout={AuthLayout} />
          <PublicRoute name="Confirm your email change" path="/change-email/:token" component={ChangeEmailView} layout={AuthLayout} />

          <UserRoute name="Home" path="/home" component={UserHomeView} layout={UserLayout} />
          <UserRoute name="Activities" path="/all-activities" component={UserActivitiesView} layout={UserLayout} />
          <UserRoute exact name="Saljpresentationer" path="/sale-presentations" component={UserSalePresentations} layout={UserLayout} />
          <UserRoute exact name="Välkomstguide" path="/welcomeguide" component={UserWelcomeGuide} layout={UserLayout} />
          <UserRoute exact name="Saljvideos" path="/sale-videos" component={UserSaleVideos} layout={UserLayout} />
          <UserRoute exact name="Support" path="/support" component={UserSupportView} layout={UserLayout} />

          <UserRoute exact name="SettingsChangePassword" path="/settings" component={UserSettingsView} layout={UserLayout} />
          <UserRoute exact name="SettingsImage" path="/settings/image" component={UserSettingsImageView} layout={UserLayout} />
          <UserRoute exact name="SettingsChangePassword" path="/settings/change-password" component={UserSettingsChangePasswordView} layout={UserLayout} />
          <UserRoute exact name="SettingsChangeEmail" path="/settings/change-email" component={UserSettingsChangeEmailView} layout={UserLayout} />
          <UserRoute exact name="UserSettingsTestInfo" path="/settings/test-info" component={UserSettingsTestInfoView} layout={UserLayout} />
          <UserRoute exact name="SettingsRemoveAccount" path="/settings/remove-account" component={UserSettingsRemoveAccountView} layout={UserLayout} />

          <Route path="/admin" component={AdminRoutes} />
          <Route exact path="/" render={() => <Redirect to="/login" />} />

          <PublicRoute component={GenericNotFoundView} layout={EmptyLayout} />
        </Switch>
        <ModalRoute path="/home/modal-example" component={UserHomeModalExampleModal} parentPath="/home" closeModal="closeModal" />
      </Suspense>
    </>
  )
}

export default Routes
