import React from 'react'
import RemoveAccount from '../../../components/RemoveAccount/RemoveAccount'
import PageLayout from '../../../components/PageLayout/PageLayout'
import SettingsLayout from '../../../components/SettingsLayout/SettingsLayout'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  pageTitle: {
    id: 'App.SettingsRemoveAccount.pageTitle',
    defaultMessage: 'Settings - Remove Account',
  },
})

const UserSettingsView = () => {
  return (
    <PageLayout pageTitleKey={messages.pageTitle}>
      <SettingsLayout active="delete">
        <RemoveAccount />
      </SettingsLayout>
    </PageLayout>
  )
}

export default UserSettingsView
