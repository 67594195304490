/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import styles from './AppCard.module.css'
import { Link } from 'react-router-dom'
import fallbackImage from './fallback-image.svg'
import pdfgenerator from './pdfgenerator.svg'
import salespresos from './sales-presos.svg'
import skickaGront from './skickagront.png'
import partitavling from './partitavling.png'
import welcomeguide from './welcomeguide.jpg'

const images = {
  fallbackImage,
  pdfgenerator,
  salespresos,
  skickaGront,
  partitavling,
  welcomeguide,
}

// eslint-disable-next-line no-unused-vars
const LinkTag = ({ href, children, token, ...props }) => {
  if (href) {
    const token = window.localStorage.getItem('token')

    /* eslint-disable-next-line */
    return (
      <a href={`${href}?token=${token}`} {...props}>
        {children}
      </a>
    )
  }

  return <Link {...props}>{children}</Link>
}

const getCustomImageStyles = (id) => {
  if (id === 'pdfgenerator') return { marginTop: 16 }
  if (id === 'salespresos') return { marginTop: 20 }
  if (id === 'salesvideos')
    return {
      display: 'none',
    }
  if (id === 'welcomeguide')
    return {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      objectFit: 'cover',
      top: 0,
      left: 0,
      margin: 0,
      position: 'absolute',
      objectPosition: '72%',
      borderRadius: 5,
    }
  return {}
}

const getCustomContentStyles = (id) => {
  if (id === 'salesvideos') return { color: 'white', marginTop: 153 }
  if (id === 'welcomeguide') return { color: 'white', marginTop: 153 }
  return {}
}

const AppCard = ({ app, user, isAdmin, skipAuthCheck = false }) => {
  if (!skipAuthCheck) {
    if (app.id === 'admin' && !isAdmin) return null
    if (app.id !== 'admin' && !user.apps.includes(app.id)) return null
  }

  return (
    <LinkTag className={styles.card} to={app.link} href={app.href}>
      <img style={getCustomImageStyles(app.id)} src={app.imageKey ? images[app.imageKey] : fallbackImage} alt={app.title} className={styles.img} />

      {app.video && (
        <video autoPlay loop muted className={styles.video}>
          <source src={app.video} type="video/mp4" />
        </video>
      )}

      <div className={styles.content} style={getCustomContentStyles(app.id)}>
        <div className={styles.title}>{app.title}</div>
        <div className={styles.description}>{app.description}</div>
      </div>
    </LinkTag>
  )
}
export default AppCard
