import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import getCurrentUser from './getCurrentUser.gql'

export const getCurrentUserQuery = getCurrentUser

export default function withUser(WrappedComponent) {
  return (props) => {
    const { loading, data = {} } = useQuery(getCurrentUser)
    return <WrappedComponent loading={loading} user={data.currentUser} {...props} />
  }
}
