import { message } from 'antd'
import CenteredLoader from 'components/CenteredLoader/CenteredLoader'
import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import { useIntl } from 'react-intl'
import EmployeeFormWithDecorator from './form'
import getCurrentUser from './getCurrentUser.gql'
import editUser from './editUser.gql'

class EmployeeForm extends Component {
  state = {
    fieldErrors: {},
  }
  onCompleted({ currentUser }) {
    if (currentUser.editUser.errors) {
      this.setState({ fieldErrors: currentUser.editUser.errors })
    } else {
      message.success(this.props.intl.formatMessage({ id: 'components.EmployeeForm.successMessage', defaultMessage: 'Successfully updated your user details' }))
    }
  }

  render() {
    const { loading: queryLoading, employee = {}, user } = this.props

    return (
      <Mutation mutation={editUser} refetchQueries={[{ query: getCurrentUser }]} awaitRefetchQueries={true} onCompleted={(res) => this.onCompleted(res)}>
        {(mutate, { loading }) => (
          <EmployeeFormWithDecorator
            initialValues={employee}
            fields={employee}
            fieldErrors={this.state.fieldErrors}
            user={user}
            onSubmit={(input) => {
              mutate({
                variables: { input },
              })
            }}
            saving={loading || queryLoading}
          />
        )}
      </Mutation>
    )
  }
}

const EmployeeQuery = () => {
  const intl = useIntl()
  return (
    <Query query={getCurrentUser}>
      {({ loading, error, data = {} }) => {
        if (error) return `Error! ${error.message}`
        if (loading) return <CenteredLoader />
        const user = data.currentUser
        const employee = user && user.employee

        return <EmployeeForm employee={employee} user={user} intl={intl} />
      }}
    </Query>
  )
}

export default EmployeeQuery
