import React from 'react'

import BugsnagContext from 'contexts/bugsnag-context'

/* Usage:
import { withBugsnag } from "hocs"

export default withBugsnag(OrganizationLayout)

Gives props: bugsnagClient
*/

export default function withBugsnag(WrappedComponent) {
  return class extends React.Component {
    render() {
      return <BugsnagContext.Consumer>{({ client }) => <WrappedComponent bugsnagClient={client} {...this.props} />}</BugsnagContext.Consumer>
    }
  }
}
