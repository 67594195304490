import { Card, message } from 'antd'
import TokenWrapper from 'components/TokenWrapper'
import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { injectIntl } from 'react-intl'
import { Redirect, withRouter } from 'react-router-dom'
import { withAuth } from 'services/auth'
import ResetPasswordForm from './reset-password-form'
import resetPasswordMutation from './resetPasswordMutation.gql'

class ResetPasswordView extends Component {
  state = {
    fieldErrors: {},
  }
  onCompleted({ resetPassword }) {
    if (resetPassword.errors) {
      this.setState({ fieldErrors: resetPassword.errors })
    } else {
      message.success(
        this.props.intl.formatMessage({
          id: 'views.ResetPasswordView.successMessage',
          defaultMessage: 'Your password has been changed. And we took the opportunity to log you in. Welcome!',
        })
      )
      this.props.handleLoginRes(resetPassword)
    }
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/home" />
    }

    return (
      <Card style={{ margin: '60px auto' }} bordered={false}>
        <TokenWrapper type="reset-password">
          <Mutation mutation={resetPasswordMutation} onCompleted={(args) => this.onCompleted(args)}>
            {(mutate, { loading, error }) => (
              <ResetPasswordForm
                fieldErrors={this.state.fieldErrors}
                error={error}
                onSubmit={(input) => {
                  mutate({
                    variables: { ...input, token: this.props.match.params.token },
                  })
                }}
                saving={loading}
              />
            )}
          </Mutation>
        </TokenWrapper>
      </Card>
    )
  }
}

export default injectIntl(withRouter(withAuth(ResetPasswordView)))
