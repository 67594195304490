import { Icon, Result } from 'antd'
import FancyButton from 'components/FancyButton/FancyButton'
import TokenWrapper from 'components/TokenWrapper'
import React from 'react'
import { Mutation } from 'react-apollo'
import { FormattedMessage } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import { withAuth } from 'services/auth'
import changeEmail from './changeEmail.gql'

const ChangeEmailView = ({ match, isAuthenticated }) => {
  return (
    <TokenWrapper type="change-email">
      {({ decodedToken }) => (
        <Mutation mutation={changeEmail} variables={{ token: match.params.token }}>
          {(mutate, { loading, data }) => {
            if (data && data.changeEmail) {
              return (
                <Result
                  title={<FormattedMessage key="successMessage" defaultMessage="Your email was changed to" />}
                  subTitle={decodedToken.email}
                  icon={<Icon type="smile" theme="twoTone" />}
                  extra={
                    isAuthenticated ? (
                      <Link to="/login">
                        <FancyButton type="primary">
                          <FormattedMessage key="goToHomeButton" defaultMessage="Go to home" />
                        </FancyButton>
                      </Link>
                    ) : (
                      <Link to="/login">
                        <FancyButton type="primary">
                          <FormattedMessage key="loginButton" defaultMessage="Log in" />
                        </FancyButton>
                      </Link>
                    )
                  }
                />
              )
            }
            return (
              <Result
                title={<FormattedMessage key="confirmTitle" defaultMessage="Confirm your email change" />}
                subTitle={decodedToken.email}
                icon={<Icon type="mail" theme="twoTone" />}
                extra={
                  <FancyButton loading={loading} block onClick={mutate}>
                    <FormattedMessage key="confirmButton" defaultMessage="Confirm email change" />
                  </FancyButton>
                }
              />
            )
          }}
        </Mutation>
      )}
    </TokenWrapper>
  )
}

export default withAuth(withRouter(ChangeEmailView))
