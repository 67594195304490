/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Icon, Row, Col } from 'antd'
import './SalesVideos.css'
import seFlag from './se-optimized.png'
import enFlag from './en.png'
import salesbg from './sales-bg.jpg'

const SalesVideos = () => {
  return (
    <div className="sales-video-boxes">
      <Row gutter={16}>
        <Col span={10}>
          <div className="sales-video-box sales-video-box--intro">
            <div className="sales-video-box__content">
              <h1 level={4}>INSTRUKTIONSFILMER</h1>
              <p
                style={{
                  marginTop: 300,
                }}
              >
                På den här sidan hittar du enkla instruktionsfilmer inom områden som kan beröra dina kunder. Det kan t ex vara förpackningstips eller fossilfria transporter.
              </p>
              <p>
                Filmerna kommer att byggas på över tid med flera nya inom andra områden och de kommer att finnas tillgängliga med svensk voice over och textning samt engelsk
                version med textning. Använd gärna filmerna i dina säljmöten med kund eller skicka länk om du tror det kan vara bra info att dela med sig av.
              </p>
            </div>
            <img src={salesbg} alt="Car" className="sales-video-box__guide-car" />
          </div>
        </Col>
        <Col span={7}>
          <div className="sales-video-box sales-video-box--sv">
            <h2 level={4}>Svensk voice over och textning</h2>
            <p>Dessa filmer har svensk voice over, med och utan svensk textning.</p>
            <div className="sales-links">
              <p>
                <strong>Utsläppsrätter</strong>
              </p>
              <video autoPlay loop muted className="sales-video__video-preview">
                <source src="/videos/utslapp-preview.mp4" type="video/mp4" />
              </video>

              <a href="https://storage.googleapis.com/dhltools.appspot.com/utslapp.mp4" style={{ margin: '12px 0', display: 'block' }}>
                Svensk voice over
                <Icon style={{ marginLeft: 20 }} type="download" />
              </a>
            </div>
            <div className="sales-links">
              <p>
                <strong>Så funkar Skicka Grönt</strong>
              </p>
              <video autoPlay loop muted className="sales-video__video-preview">
                <source src="/videos/skicka-gront-preview.mp4" type="video/mp4" />
              </video>

              {/* <a href="https://storage.googleapis.com/dhltools.appspot.com/SKICKA%20GRO%CC%88NT%20SV%20-%20UTAN%20SUB.mp4" style={{ margin: '12px 0', display: 'block' }}>
                Svensk voice over
                <Icon style={{ marginLeft: 20 }} type="download" />
              </a> */}
              <a href="https://storage.googleapis.com/dhltools.appspot.com/SKICKA%20GRO%CC%88NT%20SE%20SUB%20-%20FINAL_1.mp4" style={{ margin: '12px 0', display: 'block' }}>
                Svensk voice over, svensk textning
                <Icon style={{ marginLeft: 20 }} type="download" />
              </a>
            </div>
            <div className="sales-links">
              <p>
                <strong>Packa rätt</strong>
              </p>

              <video autoPlay loop muted className="sales-video__video-preview">
                <source src="/videos/packa-ratt-preview.mp4" type="video/mp4" />
              </video>
              <a href="https://storage.googleapis.com/dhltools.appspot.com/PACKA%20RA%CC%88TT%20SV%20SUB%20-%20FINAL_1.mp4" style={{ margin: '12px 0', display: 'block' }}>
                Svensk voice over, svensk textning
                <Icon style={{ marginLeft: 20 }} type="download" />
              </a>
            </div>

            <div className="sales-links">
              <p>
                <strong>Parcel Connect plus</strong>
              </p>

              <video autoPlay loop muted className="sales-video__video-preview">
                <source src="/videos/parcel-connect-plus.mp4" type="video/mp4" />
              </video>
              <a href="https://storage.googleapis.com/dhltools.appspot.com/DHL%20Parcel%20Connect%20Plus_SE.mp4" style={{ margin: '12px 0', display: 'block' }}>
                Svensk voice over
                <Icon style={{ marginLeft: 20 }} type="download" />
              </a>
            </div>
          </div>
          <img src={seFlag} alt="Swedish" className="sales-video-box__flag" />
        </Col>
        <Col span={7}>
          <div className="sales-video-box sales-video-box--en">
            <h2 level={4}>Engelsk textning</h2>
            <p>Filmerna här nedan är kompletterade med engelsk textremsa</p>
            <div className="sales-links">
              <p>
                <strong>Så funkar Skicka Grönt</strong>
              </p>
              <video autoPlay loop muted className="sales-video__video-preview">
                <source src="/videos/skicka-gront-preview_en.mp4" type="video/mp4" />
              </video>
              <a href="https://storage.googleapis.com/dhltools.appspot.com/SKICKA%20GRO%CC%88NT%20EN%20SUB%20-%20FINAL.mp4" style={{ margin: '12px 0', display: 'block' }}>
                Engelsk textning
                <Icon style={{ marginLeft: 20 }} type="download" />
              </a>
              <br />
            </div>

            <div className="sales-links">
              <p>
                <strong>Packa rätt</strong>
              </p>

              <video autoPlay loop muted className="sales-video__video-preview">
                <source src="/videos/packa-ratt-preview-en.mp4" type="video/mp4" />
              </video>

              <a href="https://storage.googleapis.com/dhltools.appspot.com/Packa%20ra%CC%88tt%20engelska.mp4" style={{ margin: '12px 0', display: 'block' }}>
                Engelsk textning
                <Icon style={{ marginLeft: 20 }} type="download" />
              </a>
            </div>
          </div>
          <img src={enFlag} alt="English" className="sales-video-box__flag" />
        </Col>
      </Row>
    </div>
  )
}

export default SalesVideos
