/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { withAuth } from 'services/auth'
import SalesPresentations from 'components/SalesPresentations/SalesPresentations'
const HomeView = () => {
  return (
    <div style={{ fontFamily: 'Delivery' }}>
      <SalesPresentations />
    </div>
  )
}

export default withAuth(HomeView)
