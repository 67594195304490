import React, { Component } from 'react'
import { Form, Input, Typography, Row, Col, Button } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import withFormDecorator from 'hocs/with-form-decorator'
import messages from './messages'

const { Title } = Typography

class _EmployeeForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return
      this.props.onSubmit(values)
    })
  }

  render() {
    const { form, saving, intl, user = {} } = this.props
    const { getFieldDecorator } = form

    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <Title level={3}>
          <FormattedMessage {...messages.youTitle} />
        </Title>

        <Row gutter={24}>
          <Col md={12}>
            <Form.Item label={<FormattedMessage {...messages.firstName} />}>
              {getFieldDecorator('givenName', {
                rules: [
                  {
                    required: true,
                    message: <FormattedMessage {...messages.firstNameRequired} />,
                  },
                ],
                initialValue: user.givenName,
              })(<Input placeholder={intl.formatMessage(messages.firstNamePlaceholder)} />)}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label={<FormattedMessage {...messages.lastName} />}>
              {getFieldDecorator('surname', {
                rules: [
                  {
                    required: true,
                    message: <FormattedMessage {...messages.lastNameRequired} />,
                  },
                ],
                initialValue: user.surname,
              })(<Input placeholder={intl.formatMessage(messages.lastNamePlaceholder)} />)}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={<FormattedMessage {...messages.phone} />}>
          {getFieldDecorator('phone', {
            initialValue: user.phone,
          })(<Input type="tel" placeholder={intl.formatMessage(messages.phonePlaceholder)} />)}
        </Form.Item>

        <Form.Item label={<FormattedMessage {...messages.title} />}>
          {getFieldDecorator('title', {
            initialValue: user.title,
          })(<Input placeholder={intl.formatMessage(messages.titlePlaceholder)} />)}
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" variant="cta" loading={saving}>
            <FormattedMessage {...messages.submit} />
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

const EmployeeFormWithDecorator = withFormDecorator(injectIntl(_EmployeeForm))

export default EmployeeFormWithDecorator
