import { Layout } from 'antd'
import React, { Component } from 'react'
import { withAuth } from 'services/auth'
import UserMenu from '../../components/UserMenu/UserMenu'
import DhlLogo from '../../components/DhlLogo/DhlLogo'
import AdminGhostBar from 'components/AdminGhostBar/AdminGhostBar'
import AppErrorBoundary from 'components/AppErrorBoundary/AppErrorBoundary'

class UserLayout extends Component {
  render() {
    // const { user } = this.props

    return (
      <AppErrorBoundary>
        <Layout
          style={{
            minHeight: '100vh',
          }}
        >
          <div className="alert-banners">
            <AdminGhostBar />
          </div>

          <Layout
            style={{
              minHeight: '100%',
              padding: '0 0 50px',
              backgroundImage: 'linear-gradient(180deg, #FECC00 0%, #F9EBB2 100%)',
            }}
          >
            <Layout.Header style={{ background: 'transparent' }}>
              <DhlLogo />
              <UserMenu logout={this.props.logout} />
            </Layout.Header>
            <Layout.Content
              style={{
                maxWidth: 1240,
                width: '980%',
                margin: '0 auto',
              }}
            >
              {this.props.children}
            </Layout.Content>
          </Layout>
        </Layout>
      </AppErrorBoundary>
    )
  }
}

export default withAuth(UserLayout)
