import React, { Component } from 'react'
import GenericError from 'components/GenericError/GenericError'
import withBugsnag from 'hocs/with-bugsnag'
import { withRouter } from 'react-router-dom'

class AppErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      return this.setState({ hasError: false })
    }
  }

  componentDidCatch(error, info) {
    this.props.bugsnagClient.notify(error, { metaData: info })
  }

  handleDismiss = () => {
    this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError) {
      return <GenericError onDismiss={this.handleDismiss} />
    }

    return this.props.children
  }
}

export default withRouter(withBugsnag(AppErrorBoundary))
