import { defineMessages, FormattedMessage } from 'react-intl'
import React from 'react'

const messages = defineMessages({
  compareToFirstPasswordError: "Password doesn't match",
})

const compareToFirstPassword = (form, field) => (rule, value, callback) => {
  if (value && value !== form.getFieldValue(field)) {
    callback(<FormattedMessage {...messages.compareToFirstPasswordError} />)
  } else {
    callback()
  }
}
const validateToNextPassword = (form, field) => (rule, value, callback) => {
  if (value) {
    form.validateFields([field], { force: true })
  }
  callback()
}

export { compareToFirstPassword, validateToNextPassword }
