import React, { Component } from 'react'
import { Button, Form, Icon, Input, message, Modal, Alert } from 'antd'
import withFormDecorator from 'hocs/with-form-decorator'
import { Mutation } from 'react-apollo'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { withAuth } from 'services/auth'
import removeAccount from './removeAccount.gql'
import { brandName } from 'components/Brand/Brand'

const RemoveAccountForm = injectIntl(
  withFormDecorator(({ form, saving, intl, onSubmit }) => {
    const { getFieldDecorator, validateFieldsAndScroll } = form

    const confirmModal = (values) =>
      Modal.confirm({
        title: <FormattedMessage id="confirmTitle" defaultMessage="Are you sure really sure you want to remove your account? You can't change this decision later." />,
        content: (
          <FormattedMessage
            id="confirmContent"
            defaultMessage="By removing your account you lose all access to your data. However {brandName} may keep some data in accordance with swedish invoicing and salary laws."
            values={{ brandName }}
          />
        ),
        okText: <FormattedMessage id="confirmYes" defaultMessage="Yes" />,
        okType: 'danger',
        cancelText: <FormattedMessage id="confirmNo" defaultMessage="No" />,
        onOk() {
          onSubmit(values)
        },
      })

    const handleSubmit = (e) => {
      e.preventDefault()

      validateFieldsAndScroll((err, values) => {
        if (err) return
        confirmModal(values)
      })
    }

    return (
      <Form onSubmit={handleSubmit} layout="vertical">
        <Alert
          message={<FormattedMessage id="warningTitle" defaultMessage="Warning" />}
          description={
            <FormattedMessage
              id="warningDescription"
              defaultMessage="After deleting your account there is no way to restore it. Make sure you have downloaded all the data you want to keep before proceeding."
            />
          }
          type="warning"
          showIcon
          style={{ marginBottom: 24 }}
        />

        <Form.Item label={<FormattedMessage id="passwordLabel" defaultMessage="Your password" />}>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: <FormattedMessage id="passwordRuleRequired" defaultMessage="Password is required" />,
              },
            ],
          })(<Input.Password autoComplete="off" placeholder={intl.formatMessage({ id: 'passwordPlaceholder', defaultMessage: 'Your password' })} prefix={<Icon type="lock" />} />)}
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button type="danger" htmlType="submit" loading={saving}>
            <FormattedMessage id="removeAccountButton" defaultMessage="Remove account" />
          </Button>
        </Form.Item>
      </Form>
    )
  })
)

class RemoveAccount extends Component {
  state = {
    fieldErrors: {},
  }
  onCompleted({ currentUser }) {
    if (currentUser.removeAccount.errors) {
      this.setState({ fieldErrors: currentUser.removeAccount.errors })
    } else {
      this.props.logout()
      message.success(this.props.intl.formatMessage({ id: 'components.RemoveAccount.successMessage', defaultMessage: 'Your account has been removed. Sorry to see you go!' }))
    }
  }

  render() {
    return (
      <Mutation mutation={removeAccount} onCompleted={(args) => this.onCompleted(args)}>
        {(mutate, { loading }) => (
          <RemoveAccountForm
            fieldErrors={this.state.fieldErrors}
            onSubmit={(input) => {
              mutate({
                variables: { ...input },
              })
            }}
            saving={loading}
          />
        )}
      </Mutation>
    )
  }
}

export default withAuth(withRouter(injectIntl(RemoveAccount)))
