import bugsnag from '@bugsnag/browser'
import bugsnagReact from '@bugsnag/plugin-react'
import BugsnagContext from 'contexts/bugsnag-context'
import React from 'react'

const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  appVersion: process.env.REACT_APP_VERSION || 'no-version',
  releaseStage: process.env.REACT_APP_RELEASE_STAGE || 'master',
  notifyReleaseStages: ['master', 'live', 'staging'],
})
bugsnagClient.use(bugsnagReact, React)

const ErrorBoundary = bugsnagClient.getPlugin('react')

export default ({ children }) => {
  console.log('release stage', process.env.REACT_APP_RELEASE_STAGE)
  return (
    <BugsnagContext.Provider value={{ client: bugsnagClient }}>
      <ErrorBoundary>{children({ bugsnagClient: bugsnagClient })}</ErrorBoundary>
    </BugsnagContext.Provider>
  )
}
