// This lazy loads the password check which contains a huge library to check against common used passwords
import { Input } from 'antd'
import React, { lazy, Suspense } from 'react'
const InputStrengthPasswordInput = lazy(() => import('./InputStrengthPasswordInput'))

const PasswordInput = React.forwardRef((props, ref) => (
  <Suspense fallback={<Input.Password disabled {...props} />}>
    <InputStrengthPasswordInput {...props} ref={ref} />
  </Suspense>
))
export default PasswordInput
