import React from 'react'
import './PageLayout.css'
import { Typography } from 'antd'
// import { LocalePicker } from '../../lib/locale'
import { FormattedMessage } from 'react-intl'
import cn from 'classnames'

const { Title } = Typography

const PageLayout = ({ children, pageTitleKey, pageTitle, wide = false }) => {
  return (
    <div
      className={cn('logged-in-page', {
        'logged-in-page--wide': wide,
      })}
    >
      <div className="logged-in-page__header">
        <div className="logged-in-page__page-title">{(pageTitleKey || pageTitle) && <Title level={2}>{pageTitle ? pageTitle : <FormattedMessage {...pageTitleKey} />}</Title>}</div>
        <div className="logged-in-page__locale-switcher">{/* <LocalePicker /> */}</div>
      </div>
      <main className="logged-in-page__content">{children}</main>
    </div>
  )
}

export default PageLayout
