import { Form, Icon, Input, message, Button } from 'antd'
import withFormDecorator from 'hocs/with-form-decorator'
import { Component, default as React } from 'react'
import { Mutation } from 'react-apollo'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withAuth } from 'services/auth/index'
import { withRouter } from 'react-router-dom'
import sendChangeEmailEmail from './sendChangeEmailEmail.gql'

const ChangeEmailForm = withAuth(
  injectIntl(
    withFormDecorator(({ form, saving, intl, onSubmit, user }) => {
      const { getFieldDecorator, validateFieldsAndScroll } = form

      const handleSubmit = (e) => {
        e.preventDefault()

        validateFieldsAndScroll((err, values) => {
          if (err) return
          onSubmit(values)
        })
      }

      return (
        <Form onSubmit={handleSubmit} layout="vertical">
          <Form.Item label={<FormattedMessage id="currentEmailLabel" defaultMessage="Current email" />}>
            <Input
              type="email"
              autoComplete="none"
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              disabled
              value={user.email || intl.formatMessage({ id: 'currentEmailMissing', defaultMessage: "You don't have an email set" })}
            />
          </Form.Item>
          <Form.Item label={<FormattedMessage id="emailLabel" defaultMessage="New email" />}>
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id="emailRuleRequired" defaultMessage="New email is required" />,
                },
              ],
            })(
              <Input
                type="email"
                autoComplete="username"
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder={intl.formatMessage({ id: 'emailPlaceholder', defaultMessage: 'Your new email' })}
              />
            )}
          </Form.Item>
          <Form.Item label={<FormattedMessage id="passwordLabel" defaultMessage="Your password" />}>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id="passwordRuleRequired" defaultMessage="Password is required" />,
                },
              ],
            })(
              <Input.Password
                autoComplete="password"
                placeholder={intl.formatMessage({ id: 'passwordPlaceholder', defaultMessage: 'Your password' })}
                prefix={<Icon type="lock" />}
              />
            )}
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button htmlType="submit" loading={saving}>
              <FormattedMessage id="sendChangeEmailEmailButton" defaultMessage="Change email" />
            </Button>
          </Form.Item>
        </Form>
      )
    })
  )
)

class ChangeEmail extends Component {
  state = {
    fieldErrors: {},
  }
  onCompleted({ currentUser }) {
    if (currentUser.sendChangeEmailEmail.errors) {
      this.setState({ fieldErrors: currentUser.sendChangeEmailEmail.errors })
    } else {
      message.success(
        this.props.intl.formatMessage({ id: 'components.ChangeEmail.successMessage', defaultMessage: 'An email has been sent to your new address to confirm your change' })
      )
    }
  }

  render() {
    return (
      <Mutation mutation={sendChangeEmailEmail} onCompleted={(args) => this.onCompleted(args)}>
        {(mutate, { loading }) => (
          <ChangeEmailForm
            fieldErrors={this.state.fieldErrors}
            onSubmit={(input) => {
              mutate({
                variables: { ...input },
              })
            }}
            saving={loading}
          />
        )}
      </Mutation>
    )
  }
}

export default injectIntl(withRouter(ChangeEmail))
