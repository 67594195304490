import { Icon, List, Typography } from 'antd'
import ShortDateTime from 'components/ShortDateTime'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './ActivityListItem.module.css'

const ActivityListItem = ({ item }) => (
  <List.Item>
    <List.Item.Meta
      avatar={<Icon style={{ fontSize: '22px' }} type="message" />}
      title={
        <>
          {item.linkPath ? <Link to={item.linkPath}>{item.title}</Link> : item.title}
          <Typography.Text type="secondary" className={styles.createdAt}>
            <ShortDateTime time={item.createdAt} />
          </Typography.Text>
        </>
      }
      description={<div className={styles.description}>{item.text}</div>}
    />
  </List.Item>
)

export default ActivityListItem
