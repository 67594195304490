import React from 'react'
import logo from './logo.svg'
import { withRouter } from 'react-router-dom'

const DhlLogo = ({ history, noLink }) => (
  <img
    src={logo}
    alt="DHL - Go to start"
    onClick={() => {
      if (!noLink) {
        history.push('/')
      }
    }}
    style={noLink ? {} : { cursor: 'pointer' }}
  />
)

export default withRouter(DhlLogo)
