import React, { Component } from 'react'
import { message, Typography } from 'antd'
import { withAuth } from 'services/auth'
import ForgotPasswordForm from './forgot-password-form'
import { withRouter } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import sendResetPasswordMutation from './sendResetPasswordMutation.gql'

import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
const { Title } = Typography

const messages = defineMessages({
  title: {
    id: 'ForgotPasswordView.title',
    defaultMessage: 'Forgot password?',
  },
  successMessage: {
    id: 'ForgotPasswordView.successMessage',
    defaultMessage: 'If your email exists in the system you should shortly receive an email to reset your password.',
  },
  successMessageLoggedIn: {
    id: 'ForgotPasswordView.successMessageLoggedIn',
    defaultMessage: 'You should shortly receive an email to reset your password.',
  },
})

class ForgotPasswordView extends Component {
  state = {
    fieldErrors: {},
    email: null,
  }
  onCompleted({ sendResetPasswordEmail: { errors } }) {
    if (errors) {
      this.setState({ fieldErrors: errors })
    } else {
      if (!this.props.isAuthenticated) {
        message.success(this.props.intl.formatMessage(messages.successMessageLoggedIn))
        this.props.history.push({ pathname: '/login', state: { email: this.state.email } })
      } else {
        message.success(this.props.intl.formatMessage(messages.successMessage))
        this.props.history.push({ pathname: '/home' })
      }
    }
  }

  render() {
    return (
      <Mutation mutation={sendResetPasswordMutation} onCompleted={(args) => this.onCompleted(args)}>
        {(mutate, { loading, error }) => (
          <>
            <Title level={2}>
              <FormattedMessage {...messages.title} />
            </Title>
            <ForgotPasswordForm
              fieldErrors={this.state.fieldErrors}
              error={error}
              onChange={({ email }) => this.setState({ email: email && email.value })}
              onSubmit={(input) => {
                mutate({
                  variables: input,
                })
              }}
              saving={loading}
            />
          </>
        )}
      </Mutation>
    )
  }
}

export default injectIntl(withRouter(withAuth(ForgotPasswordView)))
