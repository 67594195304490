import React from 'react'
import { Icon, Row, Col } from 'antd'
import './SalesPresentations.css'
import seFlag from './se-optimized.png'
import enFlag from './en.png'
import person from './person-optimized.jpg'
import car from './dhl-car-optimized.jpg'

const SalesPresentations = () => {
  return (
    <div className="sales-boxes">
      <Row gutter={16}>
        <Col span={8}>
          <div className="sales-box sales-box--intro">
            <div className="sales-box__content">
              <h1 level={4}>HEJ</h1>
              <p>
                Välkommen till DHL Freights säljpresentationer. Här kan du ladda ner den som Powerpoint eller i PDF-format. Du hittar också en kortfattad guide med tips kring
                användning och ett antal funktioner.
              </p>
            </div>
            <img src={car} alt="Car" className="sales-box__guide-car" />
          </div>
        </Col>
        <Col span={16}>
          <Row gutter={16}>
            <Col span={12}>
              <div className="sales-box sales-box--sv">
                <h2 level={4}>Svensk Säljpresentation</h2>
                <p>
                  Den kompletta säljpresentationen som täcker in alla våra tjänster, produkter och delar av vårt erbjudande som vi kan behöva presentera för existerande och
                  blivande kunder. Ladda ner som Power Point eller PDF.
                </p>
                <p className="sales-links">
                  <a href="https://storage.googleapis.com/dhltools.appspot.com/Sa%CC%88ljpresentation%20DHL%20Freight_SE_2311.pptx" style={{ margin: '12px 0', display: 'block' }}>
                    Säljpresentation Svenska Powerpoint
                    <Icon style={{ marginLeft: 20 }} type="download" />
                  </a>
                  <a href="https://storage.googleapis.com/dhltools.appspot.com/Sa%CC%88ljpresentation%20DHL%20Freight_SE_1123.pdf" style={{ margin: '12px 0', display: 'block' }}>
                    Säljpresentation Svenska PDF
                    <Icon style={{ marginLeft: 20 }} type="download" />
                  </a>
                </p>
              </div>
              <img src={seFlag} alt="Swedish" className="sales-box__flag" />
            </Col>
            <Col span={12}>
              <div className="sales-box sales-box--en">
                <h2 level={4}>Engelsk Säljpresentation</h2>
                <p>
                  Den kompletta säljpresentationen som täcker in alla våra tjänster, produkter och delar av vårt erbjudande som vi kan behöva presentera för existerande och
                  blivande kunder. Ladda ner som Power Point eller PDF.{' '}
                </p>
                <p className="sales-links">
                  <a href="https://storage.googleapis.com/dhltools.appspot.com/Sales%20presentation%20DHL%20Freight_EN_final.pptx" style={{ margin: '12px 0', display: 'block' }}>
                    Säljpresentation Engelska Powerpoint
                    <Icon style={{ marginLeft: 20 }} type="download" />
                  </a>
                  <a href="https://storage.googleapis.com/dhltools.appspot.com/Sales%20presentation%20DHL%20Freight_EN_final.pdf" style={{ margin: '12px 0', display: 'block' }}>
                    Säljpresentation Engelska PDF
                    <Icon style={{ marginLeft: 20 }} type="download" />
                  </a>
                </p>
              </div>
              <img src={enFlag} alt="English" className="sales-box__flag" />
            </Col>
          </Row>

          <div className="sales-box sales-box--guide">
            <h2 level={4}>Användarguide</h2>
            <p>Så anpassar du säljpresentationerna bäst för dina behov</p>
            <p className="sales-links">
              <a href="https://storage.googleapis.com/dhltools.appspot.com/Introduktion%20till%20Sa%CC%88ljpresentationen.pptx" style={{ margin: '12px 0', display: 'block' }}>
                Användarguide Svenska Powerpoint (7,9mb)
                <Icon style={{ marginLeft: 20 }} type="download" />
              </a>
              <a href="https://storage.googleapis.com/dhltools.appspot.com/Introduktion%20till%20Sa%CC%88ljpresentationen.pdf" style={{ margin: '12px 0', display: 'block' }}>
                Användarguide Svenska PDF (0,8mb)
                <Icon style={{ marginLeft: 20 }} type="download" />
              </a>
            </p>
            <img src={person} alt="Person" className="sales-box__guide-image" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SalesPresentations
