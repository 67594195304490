/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { withAuth } from 'services/auth'
import WelcomeGuide from 'components/WelcomeGuide/WelcomeGuide'
const HomeView = () => {
  return (
    <div style={{ fontFamily: 'Delivery' }}>
      <WelcomeGuide />
    </div>
  )
}

export default withAuth(HomeView)
