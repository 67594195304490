import React, { useState } from 'react'
import menuIcon from './settings.svg'
import styles from './UserMenu.module.css'
import userProfileFallback from './profile-fallback.png'
import { Link } from 'react-router-dom'
import withUser from '../withUser/withUser'

const OpenMenu = ({ user, logout }) => (
  <nav className={styles.menu}>
    <div className={styles.userProfileImage} style={{ paddingBottom: 20 }}>
      <img alt="User profile" src={user.image || userProfileFallback} />
      <div className={styles.userName}>{user.email}</div>
      <Link to="/settings" className={styles.menuItem}>
        Mina uppgifter
      </Link>
      <div onClick={logout} className={styles.menuItem}>
        Logga ut
      </div>
    </div>
  </nav>
)

const UserMenu = ({ user, logout }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <img
        alt="Toggle menu"
        onClick={() => {
          setOpen(!open)
        }}
        className={styles.menuButton}
        src={menuIcon}
      />
      {open && <OpenMenu user={user} logout={logout} />}
    </>
  )
}

export default withUser(UserMenu)
