import React, { Component } from 'react'
import { hot } from 'react-hot-loader'
import { ModalContainer } from 'react-router-modal'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routing'
import { ApolloProvider } from 'react-apollo'
import { client } from './apollo'
import { AuthProvider } from 'services/auth'
import { LocaleProvider } from 'antd'
import { getCurrentLocale } from './lib/locale'
import { IntlProvider } from 'react-intl'
import GraphqlError from './components/GraphqlError/GraphqlError'
import Bugsnag from 'components/Bugsnag'
import AppErrorBoundary from 'components/AppErrorBoundary/AppErrorBoundary'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { locale: getCurrentLocale(true), graphqlError: null }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('storage', this.localStorageUpdated)
    }
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('storage', this.localStorageUpdated)
    }
  }

  localStorageUpdated = () => {
    this.setState({ locale: getCurrentLocale() })
  }

  render() {
    const { locale } = this.state

    return (
      <Bugsnag>
        {({ bugsnagClient }) => (
          <ApolloProvider client={client}>
            <LocaleProvider locale={locale.antd}>
              <IntlProvider locale={locale.locale} messages={locale.messages}>
                <BrowserRouter>
                  <AppErrorBoundary>
                    <AuthProvider apolloClient={client} bugsnagClient={bugsnagClient}>
                      <GraphqlError>
                        <Routes />
                        <ModalContainer />
                      </GraphqlError>
                    </AuthProvider>
                  </AppErrorBoundary>
                </BrowserRouter>
              </IntlProvider>
            </LocaleProvider>
          </ApolloProvider>
        )}
      </Bugsnag>
    )
  }
}

export default hot(module)(App)
