import React from 'react'
import { AuthConsumer } from 'services/auth'
import ChangePassword from '../../../components/ChangePassword/ChangePassword'
import PageLayout from '../../../components/PageLayout/PageLayout'
import SettingsLayout from '../../../components/SettingsLayout/SettingsLayout'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  pageTitle: {
    id: 'App.SettingsChangePassword.pageTitle',
    defaultMessage: 'Settings - Change password',
  },
})

const UserSettingsView = () => {
  return (
    <PageLayout pageTitleKey={messages.pageTitle}>
      <SettingsLayout active="password">
        <AuthConsumer>{({ user }) => user && <ChangePassword user={user} />}</AuthConsumer>
      </SettingsLayout>
    </PageLayout>
  )
}

export default UserSettingsView
