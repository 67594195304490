import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import './FancyButton.css'
import { Spin, Icon } from 'antd'
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} twoToneColor={'#000000'} spin />

const FancyButton = ({ children, loading, size, type, block, className, variant, ...props }) => {
  return (
    <button
      className={cn(className, 'fancy-button', {
        [`fancy-button--${size}`]: size,
        [`fancy-button--${variant}`]: variant,
        'fancy-button--block': block,
      })}
      type={type}
      {...props}
    >
      {loading && <Spin indicator={antIcon} style={{ marginRight: 12, marginLeft: -12 }} />}
      <span>{children}</span>
    </button>
  )
}

FancyButton.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'small']),
  type: PropTypes.string,
  block: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['secondary']),
}

FancyButton.defaultProps = {
  loading: false,
  type: 'button',
  className: '',
}

export default FancyButton
