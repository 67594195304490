import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { redirectToReturnUrl, getReturnUrlFromParams } from '../lib/return-url'

class LoginView extends Component {
  constructor(props) {
    super(props)
    const returnUrl = getReturnUrlFromParams()

    if (returnUrl) {
      window.localStorage.setItem('returnUrl', returnUrl)
    }

    this.state = {
      returnUrl,
    }
  }

  render() {
    console.log('getToken')
    const { returnUrl } = this.state
    console.log({ returnUrl })
    if (!returnUrl) {
      return <div style={{ textAlign: 'center' }}>Url parameter returnUrl is missing.</div>
    }

    const token = window.localStorage.getItem('token')
    if (token) {
      console.log('User is signed in. Redirect with token')
      redirectToReturnUrl()
      return null
    } else {
      console.log(`User is not signed in. Redirect to /login. Return url is ${returnUrl}`)
      return <Redirect to={'/login'} />
    }
  }
}

export default LoginView
