import React, { Component } from 'react'
import { Form, Input, Button } from 'antd'
import withRouterAndRef from 'hocs/withRouterAndRef'
import withFormDecorator from '.../../hocs/with-form-decorator'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import PasswordInput from 'components/PasswordInput/PasswordInput'

const messages = defineMessages({
  emailPlaceholder: {
    id: 'SignupForm.emailPlaceholder',
    defaultMessage: 'Email',
  },
  passwordPlaceholder: {
    id: 'SignupForm.passwordPlaceholder',
    defaultMessage: 'Password',
  },
  emailErrorMessage: {
    id: 'SignupForm.emailErrorMessage',
    defaultMessage: 'Please input your email!',
  },
  passwordErrorMessage: {
    id: 'SignupForm.passwordErrorMessage',
    defaultMessage: 'Please input your Password!',
  },
  cta: {
    id: 'SignupForm.cta',
    defaultMessage: 'Sign up',
  },
})

class SignupForm extends Component {
  render() {
    const { form, onSubmit, saving, intl, history } = this.props
    const { getFieldDecorator } = form

    return (
      <Form onSubmit={onSubmit} layout="vertical">
        <Form.Item>
          {getFieldDecorator('email', {
            initialValue: history.location.state && history.location.state.email,
            rules: [{ required: true, message: intl.formatMessage(messages.emailErrorMessage) }],
          })(<Input type="email" placeholder={intl.formatMessage(messages.emailPlaceholder)} size="large" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: intl.formatMessage(messages.passwordErrorMessage) }],
          })(<PasswordInput placeholder={intl.formatMessage(messages.passwordPlaceholder)} size="large" />)}
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button htmlType="submit" block size="large" loading={saving}>
            <FormattedMessage {...messages.cta} />
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default injectIntl(withFormDecorator(withRouterAndRef(SignupForm)))
