import url from 'url'

export const getReturnUrlWithToken = () => {
  const token = window.localStorage.getItem('token')
  const returnUrl = window.localStorage.getItem('returnUrl')
  const separator = returnUrl.indexOf('?') !== -1 ? '&' : '?'
  console.log({ returnUrl })
  console.log({ separator })
  const url = `${returnUrl}${separator}token=${token}`
  return url
}

export const redirectToReturnUrl = () => {
  const url = getReturnUrlWithToken()
  removeReturnUrl()
  console.log('Redirect to ', url)
  window.location.href = url.startsWith('http') ? url : `http://${url}`
}

export const getReturnUrlFromParams = () => {
  const qs = url.parse(window.location.href, true).query
  if (!qs || !qs.returnUrl) return false
  return qs.returnUrl
}

export const isLoginWithRedirect = () => {
  const returnUrl = window.localStorage.getItem('returnUrl')
  return Boolean(returnUrl)
}

export const removeReturnUrl = () => {
  window.localStorage.removeItem('returnUrl')
}
