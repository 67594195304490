import React from 'react'
import PropTypes from 'prop-types'
import logoDefault from './logoDefault.svg'
import logoDefaultSlogan from './logoDefaultSlogan.svg'
import logoBright from './logoBright.svg'
import logoBrightSlogan from './logoBrightSlogan.svg'
import { brandName } from './Brand'

const themes = {
  bright: logoBright,
  brightSlogan: logoBrightSlogan,
  default: logoDefault,
  defaultSlogan: logoDefaultSlogan,
}

const BrandLogo = ({ theme, className, style }) => {
  return <img src={themes[theme]} alt={brandName} {...{ className, style }} />
}

BrandLogo.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes)),
  className: PropTypes.string,
  style: PropTypes.object,
}

BrandLogo.defaultProps = {
  theme: 'default',
}

export default BrandLogo
