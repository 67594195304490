/*
  This hook is supplied for simplifying all matters with auth

  Setup:
  
    Add this to your App.js after react-router-dom is setup
      <AuthProvider apolloClient={client}></AuthProvider>

    And setup your apollo client like this
      const authLink = setContext((_, { headers }) => {
        const token = localStorage.getItem("ghostToken") || localStorage.getItem("token")
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ""
          }
        }
      })

      export const client = new ApolloClient({
        link: authLink.concat(
          new HttpLink({
            uri
          })
        )
      })


  Accessing Hook:
    
    Example 1: withAuth
      import { withAuth } from "services/auth"
      ...
      render() {
        const { isAuthenticated, loading } = this.props
      }
      ...
      export default withAuth(YourView)

    Example 2: useAuth
      import { useAuth } from "services/auth"
      ...
      render() {
        const { isAuthenticated, loading } = useAuth()
      }

    Example 3: AuthConsumer
      import { AuthConsumer } from "services/auth"
      ...
      render() {
        return <AuthConsumer>{({ user }) => user && <span>{user.email}</span>}</AuthConsumer>
      }

  Usable methods:

    // Login for user
    login({
      email: "email",
      password: "password"
    })

    // Signup for user
    signupAndLogin({
      email: "email"
    })

    // Log out
    logout()
    
    // Check if user is authenticated
    isAuthenticated

    // Get user object
    user

    // True if the current user is still being loaded
    loading

    // Become user by token
    ghostLogin({token: tokenOfUser, user: user})

    // Check if ghosting
    isGhost()

*/
import React, { useState, useEffect, useContext } from 'react'
import jwtDecode from 'jwt-decode'
import { useHistory } from 'react-router-dom'
import loginMutation from './loginMutation.gql'
import signUpMutation from './signUpMutation.gql'
import currentUserQuery from './currentUserQuery.gql'
import currentUserFragment from '../../fragments/current-user.gql'
import { isLoginWithRedirect, redirectToReturnUrl } from '../../lib/return-url'

export const AuthContext = React.createContext()
export const AuthConsumer = AuthContext.Consumer
export const useAuth = () => useContext(AuthContext)
const UnwrappedAuthProvider = ({ children, apolloClient, bugsnagClient = {} }) => {
  const history = useHistory()
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const getTokenPayload = () => {
      if (!getToken()) {
        return null
      }

      const payload = jwtDecode(getToken())
      if (payload.iss !== process.env.REACT_APP_JWT_ISS) {
        console.warn(`Invalid ISS from JWT Token. Logging out user. Expected ISS ${process.env.REACT_APP_JWT_ISS} but got ${payload.iss}.`)
        localStorage.removeItem('token')
        return null
      }

      return payload
    }

    const updateBugsnagTracking = (user) => {
      if (!user) return

      bugsnagClient.user = {
        id: user.id,
        email: user.email,
        roles: user.roles,
      }
    }

    const initAuth = async () => {
      const tokenPayload = getTokenPayload()
      if (tokenPayload && tokenPayload.userId) {
        const {
          data: { currentUser },
        } = await apolloClient.query({ query: currentUserQuery })

        updateBugsnagTracking(currentUser)
        setIsAuthenticated(true)
        setUser(currentUser)
      } else {
        updateBugsnagTracking()
        setIsAuthenticated(false)
      }
      setLoading(false)
    }
    initAuth()
  }, [apolloClient, bugsnagClient])

  const getCurrentUser = () => {
    if (!user) {
      return null
    }
    const res = apolloClient.readFragment({
      id: `User:${user.id}`,
      fragment: currentUserFragment,
    })

    return res
  }
  const updateBugsnagTracking = (user) => {
    if (!user) return

    bugsnagClient.user = {
      id: user.id,
      email: user.email,
      roles: user.roles,
    }
  }

  const getToken = () => {
    const ghostToken = localStorage.getItem('ghostToken')
    return ghostToken || localStorage.getItem('token')
  }

  const setToken = (token) => {
    localStorage.setItem('token', token)
  }

  const ghostLogin = ({ token, user }) => {
    localStorage.setItem('ghostToken', token)
    localStorage.setItem('ghostFromPath', location.pathname + location.search)
    setUser(user)
    history.push('/home')
  }

  const isGhost = () => {
    return !!localStorage.getItem('ghostToken')
  }

  const isAdmin = () => {
    if (user && Array.isArray(user.roles) && (user.roles.includes('superadmin') || user.roles.includes('admin'))) {
      return true
    }
    return false
  }

  const handleLoginRes = async (loginRes) => {
    setUser(loginRes.user)
    setToken(loginRes.jwtToken)
    setIsAuthenticated(true)
    updateBugsnagTracking(loginRes.user)
    setLoading(false)

    if (isLoginWithRedirect()) {
      redirectToReturnUrl()
      return null
    }

    history.push('/home')
  }

  const login = async ({ email, password }) => {
    setLoading(true)
    const {
      data: { login: loginRes },
    } = await apolloClient.mutate({
      mutation: loginMutation,
      variables: { email, password },
    })
    if (!loginRes.user) {
      setLoading(false)
      return loginRes
    }
    handleLoginRes(loginRes)
    return loginRes
  }
  const signupAndLogin = async (fields) => {
    setLoading(true)
    const {
      data: { signUp: signUpRes },
    } = await apolloClient.mutate({
      mutation: signUpMutation,
      variables: fields,
    })
    if (!signUpRes.user) {
      setLoading(false)
      return signUpRes
    }
    handleLoginRes(signUpRes)
    return signUpRes
  }
  const logout = async () => {
    if (isGhost()) {
      localStorage.removeItem('ghostToken')
      await apolloClient.resetStore()
      const {
        data: { currentUser },
      } = await apolloClient.query({
        query: currentUserQuery,
      })
      setUser(currentUser)
      history.push(localStorage.getItem('ghostFromPath') || '/admin')
    } else {
      setIsAuthenticated(false)
      setUser(null)
      localStorage.removeItem('token')
      updateBugsnagTracking()
      apolloClient.resetStore()
      window.location.reload()
    }
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user: getCurrentUser(),
        loading,
        login,
        ghostLogin,
        isGhost,
        isAdmin: isAdmin(),
        logout,
        signupAndLogin,
        handleLoginRes,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const AuthProvider = UnwrappedAuthProvider

export const withAuth = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      return <AuthConsumer>{(methods) => <WrappedComponent {...methods} {...this.props} />}</AuthConsumer>
    }
  }
}
