import React from 'react'
import ProfilePictureForm from '../../../components/ProfilePictureForm/ProfilePictureForm'
import PageLayout from '../../../components/PageLayout/PageLayout'
import SettingsLayout from '../../../components/SettingsLayout/SettingsLayout'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  pageTitle: {
    id: 'App.SettingsImage.pageTitle',
    defaultMessage: 'Settings - Profile picture',
  },
})

const UserSettingsImageView = () => {
  return (
    <PageLayout pageTitleKey={messages.pageTitle}>
      <SettingsLayout active="image">
        <ProfilePictureForm />
      </SettingsLayout>
    </PageLayout>
  )
}

export default UserSettingsImageView
