import React from 'react'

import './WelcomeGuide.css'
const WelcomeGuide = () => {
  return (
    <div style={{ color: '#C22A23', maxWidth: 800, fontSize: 16, margin: '0 auto' }}>
      <div
        style={{
          fontSize: 36,
          marginBottom: 0,
          marginTop: 100,
          color: '#C22A23',
          fontFamily: 'deliveryregular',
        }}
      >
        HÄR LADDAR DU NER
      </div>
      <h2
        style={{
          fontSize: 56,
          marginTop: 0,
          marginBottom: 0,
          color: '#C22A23',
          letterSpacing: -1,
          fontFamily: 'deliverycondensed_black',
        }}
      >
        VÄLKOMSTGUIDEN
      </h2>
      <p>
        Under våren och sommaren har vi gjort en översyn av Välkomstguiden. Den är ett viktigt instrument för att våra kunder ska komma igång snabbt och smidigt. Den besparar också
        vår kundtjänst tid genom att ge rätt information vid rätt tidpunkt i takt med att kunderna lär sig våra produkter och rutiner.
      </p>
      <p>Det finns två versioner av Välkomstguiden. En för Inköpsorganisationer (IKO), partners och medlemmar. Och en version för alla våra andra nya kunder.</p>
      <div style={{ fontSize: 18 }}>
        <a
          href="https://storage.googleapis.com/dhltools.appspot.com/DHL%20Va%CC%88lkomstguiden_partner%20och%20medlemmar.pptx"
          style={{ display: 'block', paddingTop: 10, paddingBottom: 10 }}
        >
          Partners och medlemmar (Powerpoint 18,3 MB)
        </a>
        <a href="https://storage.googleapis.com/dhltools.appspot.com/DHL%20Va%CC%88lkomstguiden_nya%20kunder.pptx" style={{ display: 'block', paddingTop: 10, paddingBottom: 10 }}>
          Nya kunder (Powerpoint 18,3 MB)
        </a>
      </div>
    </div>
  )
}

export default WelcomeGuide
