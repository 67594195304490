import React, { lazy, Suspense } from 'react'
import vs from 'react-syntax-highlighter/dist/cjs/styles/prism/vs'
const Prism = lazy(() => import('./Prism'))

const Inner = (props) => {
  return (
    <Prism {...props} style={vs}>
      {props.children}
    </Prism>
  )
}

const SyntaxHighlighter = (props) => {
  return (
    <Suspense fallback={<span></span>}>
      <Inner {...props} />
    </Suspense>
  )
}

export default SyntaxHighlighter
