import React from 'react'
import { Query } from 'react-apollo'
import dotProp from 'dot-prop-immutable'
import { Button, Empty } from 'antd'
/* Example usage

1. You must make sure to allow $after variables on your query. And add ...pagination fragment
#import "../../fragments/pagination.gql"

query UserListActivities($after: String) {
  currentUser {
    activityLog(after: $after) {
      ...pagination

2. Add in your component
// queryProps: react-apollo <Query /> props
// dataRef: dotProp string of where the collectiton is in the graphql response
// showEmpty: Show empty error when no results. Defaults to true
// emptyText: String when no results are found. Defaults to "No results found"
// errorText: String when query error. Defaults to "Error loading results."

import ApolloQueryLoadMore from "components/ApolloQueryLoadMore"

<ApolloQueryLoadMore queryProps={{ query: userListActivities }} dataRef="currentUser.activityLog" showEmpty={true}>
  {({ dataSource, loading }) => (
    <List
      itemLayout="horizontal"
      loading={loading}
      dataSource={dataSource}
      renderItem={item => (
        <List.Item>
        ...
        </List.Item>
      )}
    />
  )}
</ApolloQueryLoadMore>

*/

export default function ApolloQueryLoadMore({ queryProps = {}, children, dataRef, errorText, emptyText, showEmpty = true }) {
  if (!dataRef) {
    throw new Error('ApolloQueryLoadMore requires dataRef prop')
  }

  if (!queryProps.query) {
    throw new Error('ApolloQueryLoadMore requires queryProps to have a query. Example queryProps={{query: listRecentActivities}}')
  }

  const checkRequiredQueryBodyString = (string) => {
    if (queryProps.query.loc.source.body.indexOf(string) === -1) {
      throw new Error(`ApolloQueryLoadMore requires the query to contain ${string}`)
    }
  }

  checkRequiredQueryBodyString('fragments/pagination.gql')
  checkRequiredQueryBodyString('$after: String')

  return (
    <Query {...queryProps} notifyOnNetworkStatusChange>
      {({ loading, error, data = {}, fetchMore }) => {
        const collection = dotProp.get(data, dataRef)

        if (collection && collection.__typename !== 'Collection') {
          throw new Error(`ApolloQueryLoadMore dataRef must point to a Graphql Collection type. But it points to ${collection.__typename}`)
        }

        const dataSource = (dotProp.get(collection, 'edges') || []).map((edge) => edge.node)

        const loadMore = ({ after }) => () =>
          fetchMore({
            variables: {
              after,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newCollection = dotProp.get(fetchMoreResult, dataRef)
              const previousCollection = dotProp.get(previousResult, dataRef)

              return dotProp.set(previousResult, dataRef, {
                ...newCollection,
                edges: [...previousCollection.edges, ...newCollection.edges],
              })
            },
          })
        return (
          <React.Fragment>
            {children({ dataSource, loading, error })}

            {error && <Empty description={<div style={{ color: 'red' }}>{errorText || 'Error loading results.'}</div>} />}
            {showEmpty && collection && collection.totalCount === 0 && <Empty description={emptyText || 'No results found'} />}

            {collection && collection.pageInfo.hasNextPage && (
              <Button block onClick={loadMore({ after: collection.pageInfo.endCursor })}>
                Load more
              </Button>
            )}
          </React.Fragment>
        )
      }}
    </Query>
  )
}
