import React, { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'

import './ImageUploader.css'

const activeStyle = {
  backgroundColor: 'rgba(255,255,255,.6)',
}

const acceptStyle = {
  borderColor: '#fff',
}

const rejectStyle = {
  backgroundColor: 'rgba(255, 0, 0, .3)',
}

const resizeImage = (base64Img, cb) => {
  const MAX_WIDTH = 500
  const MAX_HEIGHT = 500
  const img = new Image()
  img.src = base64Img

  img.onload = () => {
    let { width, height } = img
    if (width <= MAX_WIDTH && height <= MAX_HEIGHT) cb(base64Img)

    if (width > height) {
      height = Math.round((height * MAX_WIDTH) / width)
      width = MAX_WIDTH
    } else {
      width = Math.round((width * MAX_HEIGHT) / height)
      height = MAX_HEIGHT
    }

    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, width, height)
    cb(canvas.toDataURL())
  }
}

function StyledDropzone({ setImage }) {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0]

      const reader = new FileReader()
      reader.onload = (e) => {
        resizeImage(e.target.result, (resized) => {
          if (resized) {
            setImage(resized)
          }
        })
      }
      reader.readAsDataURL(file)
    },
  })

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragActive, isDragReject]
  )

  return (
    <div {...getRootProps({ style })} className="image-uploader__dropzone">
      <input {...getInputProps()} />
    </div>
  )
}

const ImageUploader = ({ image, onChange }) => {
  return (
    <div className="image-uploader">
      {image && (
        <div className="image-uploader__preview">
          <img alt="Profile pic" src={image} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
      )}
      <StyledDropzone
        setImage={(url) => {
          onChange(url)
        }}
      />

      {!image && (
        <div className="image-uploader__placeholder">
          <p className="image-uploader__placeholder-text">Dra och släpp eller</p>
          <div className="image-uploader__placeholder-btn">Ladda upp</div>
        </div>
      )}
    </div>
  )
}

export default ImageUploader
