import React from 'react'
import ImageUploader from './ImageUploader'
import { useMutation } from '@apollo/react-hooks'
import updateUserImage from './updateUserImage.gql'
import withUser, { getCurrentUserQuery } from '../withUser/withUser'

import { Spin } from 'antd'

const ProfilePictureForm = ({ user = {}, loading: userLoading }) => {
  const [updateImage, { loading }] = useMutation(updateUserImage, {
    refetchQueries: [{ query: getCurrentUserQuery }],
    awaitRefetchQueries: true,
  })
  const { image } = user

  const anythingLoading = userLoading || loading
  return (
    <>
      {anythingLoading && (
        <div>
          <Spin />
          {' Laddar upp...'}
        </div>
      )}

      <ImageUploader
        image={image}
        onChange={(imgdata) => {
          updateImage({
            variables: {
              input: {
                image: imgdata,
              },
            },
          })
        }}
      />
    </>
  )
}

export default withUser(ProfilePictureForm)
