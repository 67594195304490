import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { withAuth } from 'services/auth'
import { useMediaQuery } from 'react-responsive'

const { Sider, Content } = Layout

const MenuWrapper = ({ children, isMobile }) => {
  if (isMobile) return <div style={{ marginBottom: 24 }}>{children}</div>
  return <Sider theme="light">{children}</Sider>
}

const SettingsLayout = ({ children, active, isAdmin }) => {
  const menuItems = [
    {
      key: 'settings',
      title: <FormattedMessage key="SettingsLayout.menuSettings" defaultMessage="Settings" />,
      to: '/settings',
    },
    {
      key: 'image',
      title: <FormattedMessage key="SettingsLayout.menuImage" defaultMessage="Profile picture" />,
      to: '/settings/image',
    },
    {
      key: 'password',
      title: <FormattedMessage key="menuPassword" defaultMessage="Password" />,
      to: '/settings/change-password',
    },
    // {
    //   key: 'email',
    //   title: <FormattedMessage key="menuEmail" defaultMessage="Change email" />,
    //   to: '/settings/change-email'
    // },
    {
      key: 'delete',
      title: <FormattedMessage key="menuDelete" defaultMessage="Delete account" />,
      to: '/settings/remove-account',
    },
  ]

  if (isAdmin) {
    menuItems.push({
      key: 'test',
      title: <FormattedMessage key="menuTest" defaultMessage="Test info" />,
      to: '/settings/test-info',
    })
  }

  const isMobile = useMediaQuery({ maxWidth: 800 })

  const contentStyle = isMobile
    ? { padding: '24px 0', margin: '0' }
    : {
        padding: '0 24px',
      }

  return (
    <Layout style={{ background: 'white' }}>
      <MenuWrapper isMobile={isMobile}>
        <Menu mode={isMobile ? 'horizontal' : 'inline'} selectedKeys={[active].filter(Boolean)}>
          {menuItems.map((item) => (
            <Menu.Item key={item.key}>
              <Link to={item.to}>{item.title}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </MenuWrapper>
      <Content style={contentStyle}>{children}</Content>
    </Layout>
  )
}

export default withAuth(SettingsLayout)
