import React from 'react'
import AppCard from '../../components/AppCard/AppCard'
import apps from '../../lib/dhl-apps.json'
import { withAuth } from 'services/auth'

const HomeView = ({ user, isAdmin }) => {
  // Every user should see salesvideos, for now
  if (!user.apps.includes('salesvideos')) {
    user.apps.push('salesvideos')
  }
  // Every user should see welcomeguide, for now
  if (!user.apps.includes('welcomeguide')) {
    user.apps.push('welcomeguide')
  }

  const hasNoApps = !isAdmin && user.apps.length === 0

  console.log({ hasNoApps })

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <h1
          style={{
            fontSize: 56,
            marginTop: 100,
            marginBottom: 0,
            color: '#C22A23',
            letterSpacing: -1,
            fontFamily: 'deliverycondensed_black',
          }}
        >
          VÄLKOMMEN TILL DHL TOOLS
        </h1>
        <p
          style={{
            fontFamily: 'deliveryregular',
            fontSize: 26,
            color: '#000',
            maxWidth: 700,
            margin: '20px auto 60px',
          }}
        >
          Här hittar du alla de verktyg du behöver för ett bra och inspirerande kundmöte samt en effektivare kundhantering.
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {hasNoApps && <div style={{ fontSize: 18 }}>Du saknar app-rättigheter.</div>}
        {apps.map((app) => (
          <AppCard app={app} key={app.id} user={user} isAdmin={isAdmin} />
        ))}

        {/* {!hasNoApps && (
          <AppCard
            app={{
              id: 'skicka-gront-tavling',
              title: 'SKICKA GRÖNT',
              description: 'Allt om tävlingen hittar du här',
              imageKey: 'skickaGront',
              href:
                'https://isharenew.dhl.com/sites/SEFreight/MS/Sljpresentationer/Forms/AllItems.aspx?RootFolder=%2Fsites%2FSEFreight%2FMS%2FSljpresentationer%2FSKICKA%20GR%C3%96NT%20S%C3%A4ljt%C3%A4vling&FolderCTID=0x012000AE1EDDCCF23C7C40BF617B41140E6C1E&View=%7BBDBC7316%2D4382%2D4FB9%2D856A%2D962CDD7040B2%7D&InitialTabId=Ribbon%2EDocument&VisibilityContext=WSSTabPersistence',
            }}
            skipAuthCheck={true}
            user={user}
            isAdmin={isAdmin}
          />
        )} */}
        {/* {!hasNoApps && (
          <AppCard
            app={{
              id: 'parti-tavling',
              title: 'PARTI',
              description: 'Säljmaterial för partikunder',
              imageKey: 'partitavling',
              href:
                'https://isharenew.dhl.com/sites/SEFreight/MS/Sljpresentationer/Forms/AllItems.aspx?RootFolder=%2Fsites%2FSEFreight%2FMS%2FSljpresentationer%2FPartitransporter%20Aktivitet%20f%C3%B6r%20tillv%C3%A4xt&PageView=Shared&FolderCTID=0x012000AE1EDDCCF23C7C40BF617B41140E6C1E&View=%7BBDBC7316%2D4382%2D4FB9%2D856A%2D962CDD7040B2%7D',
            }}
            skipAuthCheck={true}
            user={user}
            isAdmin={isAdmin}
          />
        )} */}
      </div>
    </div>
  )
}

export default withAuth(HomeView)
