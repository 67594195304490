import { Card, message, Result, Button } from 'antd'
import BigLoader from 'components/BigLoader'
import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import { withAuth } from 'services/auth'
import confirmTokenMutation from './confirmTokenMutation.gql'
import currentUserQuery from 'services/auth/currentUserQuery.gql'
import TokenWrapper from 'components/TokenWrapper'
import { FormattedMessage, injectIntl } from 'react-intl'

const Error = ({ subTitle }) => (
  <Result
    status="warning"
    title={<FormattedMessage id="global.genericErrorTitle" defaultMessage="Something went wrong" />}
    subTitle={subTitle}
    extra={
      <Button type="secondary" key="retry" onClick={() => this.confirmToken()}>
        <FormattedMessage id="global.retry" defaultMessage="Retry" />
      </Button>
    }
  />
)

const Success = ({ subTitle }) => (
  <Result
    status="success"
    title={subTitle}
    subTitle={<FormattedMessage key="successUnauthedSubTitle" defaultMessage="Continue by logging in." />}
    extra={
      <Link to="/login">
        <Button type="primary" key="login">
          <FormattedMessage id="global.login" defaultMessage="Sign in" />
        </Button>
      </Link>
    }
  />
)

class ConfirmView extends Component {
  state = {
    error: false,
    errors: [],
    loading: true,
    mutated: false,
  }

  confirmToken() {
    this.setState({ error: false, loading: true })

    const refetchQueries = this.props.isAuthenticated ? [{ query: currentUserQuery }] : []

    this.props.client
      .mutate({
        refetchQueries,
        awaitRefetchQueries: true,
        mutation: confirmTokenMutation,
        variables: { token: this.props.match.params.token },
      })
      .then(({ data }) => {
        if (!data.confirmToken.errors) {
          message.info(this.props.intl.formatMessage({ id: 'views.confirm.successMessage', defaultMessage: 'Your email was successfully confirmed' }))
          this.setState({ error: false, loading: false })
        } else {
          this.setState({ errors: data.confirmToken.errors, error: true, loading: false })
        }
      })
  }
  componentDidUpdate() {
    if (!this.props.loading && !this.state.mutated) {
      this.setState({ mutated: true })
      this.confirmToken()
    }
  }

  render() {
    const { isAuthenticated, loading } = this.props

    if (this.state.loading || loading) {
      return <BigLoader />
    }
    if (this.state.error) {
      return <Error subTitle={this.state.errors[0].message} />
    }
    if (isAuthenticated) {
      return <Redirect to={'/home'} />
    }
    return <Success subTitle={<FormattedMessage key="successMessage" defaultMessage="Your email was successfully confirmed" />} />
  }
}
const WithTokenWrapper = (props) => (
  <Card style={{ width: 480, margin: '60px auto' }} title={<FormattedMessage key="title" defaultMessage="Confirming your email" />}>
    <TokenWrapper type="email-confirmation">
      <ConfirmView {...props} />
    </TokenWrapper>
  </Card>
)

export default injectIntl(withApollo(withAuth(WithTokenWrapper)))
