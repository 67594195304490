import React from 'react'
import { Typography } from 'antd'
import PageLayout from '../../../components/PageLayout/PageLayout'
import SettingsLayout from '../../../components/SettingsLayout/SettingsLayout'
import withUser from '../../../components/withUser/withUser'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  pageTitle: {
    id: 'App.SettingsTestInfo.pageTitle',
    defaultMessage: 'Settings - Test info',
  },
})

const { Paragraph, Title } = Typography

const UserRaw = withUser(({ user, loading }) => {
  if (loading) return null
  if (!user) return null

  // eslint-disable-next-line no-unused-vars
  const { image, ...userdata } = user

  return <pre>{JSON.stringify(userdata, null, 2)}</pre>
})

const UserSettingsView = () => {
  const token = localStorage.getItem('token')
  return (
    <PageLayout pageTitleKey={messages.pageTitle}>
      <SettingsLayout active="test">
        <div>
          <Title level={4}>Header for testing graphql request.</Title>
          <Paragraph copyable>
            {`
          { "authorization": "Bearer ${token}"}
        `}
          </Paragraph>
          <Title level={4}>Latest release</Title>
          <Paragraph copyable>{process.env.REACT_APP_VERSION || 'REACT_APP_VERSION not set'}</Paragraph>

          <Title level={4}>User</Title>
          <Paragraph copyable>
            <UserRaw />
          </Paragraph>
        </div>
      </SettingsLayout>
    </PageLayout>
  )
}

export default UserSettingsView
