import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { withAuth } from 'services/auth'
import PageLayout from '../../components/PageLayout/PageLayout'
import { Row, Col, Card } from 'antd'

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  pageTitle: {
    id: 'App.Support.pageTitle',
    defaultMessage: 'Support',
  },
})
class SupportView extends Component {
  render() {
    return (
      <PageLayout pageTitleKey={messages.pageTitle}>
        <Row gutter={32}>
          <Col sm={24} md={12}>
            <Card style={{ marginBottom: 24 }}>
              <h2>
                <FormattedMessage key="contactTitle" defaultMessage="Contact us!" />
              </h2>
              <p>
                <FormattedMessage key="contactCaption" defaultMessage="You can reach us, at all hours just give us a call." />
              </p>

              <p style={{ fontSize: 28 }}>‭ 070 12345678</p>
            </Card>
          </Col>
        </Row>
      </PageLayout>
    )
  }
}

export default withAuth(SupportView)
