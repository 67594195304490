import React, { Component } from 'react'
import styles from './auth-layout.module.css'
// import { LocalePicker } from '../../lib/locale'
import DhlLogo from '../../components/DhlLogo/DhlLogo'

class EmptyLayout extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <DhlLogo noLink />
        </div>
        <div className={styles.main}>
          {/* <header className={styles.header}>
            <LocalePicker />
          </header> */}
          <main className={styles.content}>{this.props.children}</main>
        </div>
      </div>
    )
  }
}

export default EmptyLayout
