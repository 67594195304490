/* eslint-disable jsx-a11y/anchor-is-valid */
import SalesVideos from 'components/SalesVideos/SalesVideos'
import React from 'react'
import { withAuth } from 'services/auth'
const HomeView = () => {
  return (
    <div style={{ fontFamily: 'Delivery' }}>
      <SalesVideos />
    </div>
  )
}

export default withAuth(HomeView)
