import React from 'react'
import { Form } from 'antd'
import merge from 'deepmerge'

/* Example usage
import React, { Component } from "react"
import { Form, Input, Button } from "antd"
import { useMutation, useQuery } from '@apollo/react-hooks'

import withFormDecorator from "hocs/with-form-decorator"

const UserForm = withFormDecorator(({ form, saving, intl, onSubmit, user }) => {
  const { getFieldDecorator, validateFieldsAndScroll } = form

  const handleSubmit = e => {
    e.preventDefault()

    validateFieldsAndScroll((err, values) => {
      if (err) return
      onSubmit(values)
    })
  }

  return (
    <Form onSubmit={handleSubmit} layout="vertical">
      <Form.Item>
        {getFieldDecorator("user.name")(<Input />)}
      </Form.Item>
      <Form.Item>
        <Button type="submit" loading={saving}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
})

const EditUser = ({ user }) => {
  const [fieldErrors, setFieldErrors] = useState({})

  const [adminEditUser, { loading }] = useMutation(editUserMutation, {
    onCompleted: ({ editUser }) => {
      if (adminEditUser.errors) {
        setFieldErrors(adminEditUser.errors)
      } else {
        message.success('Successfully saved')
      }
    }
  })

  return (
    <UserForm
      fieldErrors={fieldErrors}
      onSubmit={input => {
        adminEditUser({
          variables: { user: { id: user.id, ...input } }
        })
      }}
      saving={loading}
    />
  )
}
*/

// Iterates through an object and creates form fields for the fields.
// { user: {name: "patrick"} } would create form field "user.name". Which
// is then used in the view
function internalMapPropsToFields(propFields, parentNameRef = '') {
  // parentNameRef controls when an object is sent in and makes it into dotted $name
  // { user: { name: "Patrick" } } -> getFieldDecorator("user.name")
  let formFields = {}

  Object.keys(propFields || {}).forEach((propFieldKey) => {
    if (typeof propFields[propFieldKey] === 'object' && propFields[propFieldKey].value === undefined && propFields[propFieldKey].dirty === undefined) {
      parentNameRef = propFieldKey === '' ? parentNameRef : `${parentNameRef}.${propFieldKey}`
      const res = internalMapPropsToFields(propFields[propFieldKey], parentNameRef)
      parentNameRef = res.parentNameRef
      formFields[propFieldKey] = res.formFields
    } else {
      formFields[propFieldKey] = Form.createFormField({
        ...propFields[propFieldKey],
        value: propFields[propFieldKey].value,
      })
    }
  })

  return { formFields, parentNameRef }
}

function create(args = {}) {
  return Form.create({
    ...args,
    onFieldsChange: (props, changedFields) => {
      if (props.onChange) {
        props.onChange(changedFields)
      }
    },
    mapPropsToFields: (props) => {
      const propFields = props.fields || {}
      return internalMapPropsToFields(propFields).formFields
    },
  })
}

function withFormDecorator(WrappedComponent, { customFieldMap } = {}) {
  return class extends React.Component {
    constructor(props) {
      super(props)
      this.newForm = create()(WrappedComponent)
      this.state = {
        fields: {},
      }
    }

    /* Takes updated props.fields and makes them in to an object. This to perserve state
      of form validation and field values.
      getDerivedStateFromProps({fields: {user: {name: "Patrick"}}}, {}) ->
      { fields: { user: { name: { value: "Patrick" } } } }
    */
    static getDerivedStateFromProps = (props, state) => {
      // Takes backend errors in the fieldErrors props and merge them into the values in form state
      // So we can display those when frontend validation has been successful
      if (state.fieldErrors && props.fieldErrors !== state.fieldErrors) {
        let formattedMergeErrors = {}
        props.fieldErrors.forEach((error) => (formattedMergeErrors[error.field] = { errors: props.fieldErrors.filter((fieldError) => fieldError.field === error.field) }))

        return { fields: merge(state.fields, formattedMergeErrors), fieldErrors: props.fieldErrors }
      }

      if (Object.keys(state.fields).length !== 0) {
        return state
      }

      const recursiveValueMapping = (propsFields) => {
        let newFields = {}

        Object.keys(propsFields || {}).forEach((key) => {
          if (typeof propsFields[key] === 'object' && propsFields[key] !== null && !Array.isArray(propsFields[key])) {
            newFields[key] = recursiveValueMapping(propsFields[key])
          } else {
            newFields[key] = {
              value: propsFields[key],
            }
          }
        })

        return newFields
      }

      const newFields = recursiveValueMapping(props.fields)

      /* customFieldMap allows for custom mapping of object fields into form fields.
        Example:
          export default withFormDecorator(UserForm, {
            customFieldMap: {
              dateRange: fields => [apiToMoment(fields.startTime), apiToMoment(fields.endTime)]
            }
          })
      */
      if (customFieldMap) {
        Object.keys(customFieldMap).forEach((key) => (newFields[key] = { value: customFieldMap[key](props.fields) }))
      }

      return { fields: { ...newFields, ...state.fields }, fieldErrors: props.fieldErrors }
    }

    handleFormChange = (changedFields) => {
      this.props.onChange && this.props.onChange(changedFields)
      this.setState((prevState) => {
        const overwriteMerge = (_, sourceArray) => sourceArray
        return {
          ...prevState,
          fields: merge(prevState.fields, changedFields, { arrayMerge: overwriteMerge }),
        }
      })
    }

    render() {
      const NewForm = this.newForm
      return <NewForm {...{ ...this.props, fields: this.state.fields }} onChange={this.handleFormChange} />
    }
  }
}

export default withFormDecorator
