/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

export default function ClearLocalStorage() {
  const [cleared, setCleared] = useState(false)
  return (
    <div style={{ fontSize: 12 }}>
      {cleared ? (
        <>Klart! Prova logga in igen 👍</>
      ) : (
        <>
          Inloggningsproblem?
          <br />
          <a
            href="#"
            onClick={() => {
              window.localStorage.clear()
              setCleared(true)
            }}
          >
            Töm din cache genom att klicka här
          </a>
        </>
      )}
    </div>
  )
}
