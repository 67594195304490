import { defineMessages } from 'react-intl'

export default defineMessages({
  youTitle: 'About you',
  bankTaxTitle: 'Bank & Taxes',
  contactTitle: 'Contact information',
  taxTable: 'Tax Table',
  taxTablePlaceholder: '33',
  taxTableRequired: 'Tax table is required',
  personalIdentityNumber: 'Personnr',
  personalIdentityNumberPlaceholder: 'yyyymmddxxxx',
  personalIdentityNumberDisabledText: "Personal identity number can't be changed",
  personalIdentityNumberRequired: 'Personal identity number is required',
  personalIdentityNumberInvalid: 'Make sure it follows pattern YYYYMMDD-NNNN and that it is not fake.',
  firstName: 'First name',
  firstNamePlaceholder: 'Maja',
  firstNameRequired: 'First name is required',
  lastName: 'Last name',
  lastNamePlaceholder: 'Karlsson',
  lastNameRequired: 'Last name is required',
  title: 'Title',
  titlePlaceholder: 'Title',
  address: 'Adress',
  addressPlaceholder: 'Storgatan 1',
  zipCode: 'Postnummer',
  zipCodePlaceholder: '11234',
  city: 'Ort',
  cityPlaceholder: 'Stockholm',
  country: 'Land',
  countryPlaceholder: 'Sverige',
  phone: 'Telefon',
  phonePlaceholder: '07012345678',
  clearingNo: 'Bank - Clearingnummer',
  clearingNoPlaceholder: 'xxxx',
  clearingNoRequired: 'Clearing number is required',
  bankAccountNo: 'Bank - Kontonummer',
  bankAccountNoPlaceholder: 'xxxxxxx',
  bankAccountNoRequired: 'Bank account numer is required',

  submit: 'Spara',
})
