import React from 'react'
import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

export default ({ onDismiss }) => {
  return (
    <Result
      style={{ margin: '40px auto' }}
      title="500"
      status="500"
      subTitle={<FormattedMessage key="subTitle" defaultMessage="Ooops, something is wrong" />}
      extra={
        <Link to="/home" onClick={onDismiss}>
          <Button type="primary">
            <FormattedMessage key="homeButton" defaultMessage="Back home" />
          </Button>
        </Link>
      }
    />
  )
}
